import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTeachersList() {
  // Use toast
  const toast = useToast()

  const refTeachersList = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'email', sortable: true },
    { key: 'phone_number', sortable: true },
    // { key: 'role', sortable: true },
    { key: 'actions' },
  ]
  const per_page = ref(10)
  const totalUsers = ref(0)
  const current_page = ref(1)
  const per_pageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const addTeacherFirstName = ref('')
  const addTeacherLastName = ref('')
  const addTeacherEmail = ref('')
  const addTeacherPassword = ref('')
  const addTeacherConfirmPassword = ref('')
  const addTeacherPhoneNumber = ref('')

  const teacherData = ref(null)

  const addToSchoolModal = ref(false)
  const addToSchoolTeacherId = ref('')
  const addToSchoolId = ref('')

  const updatePermissionsModal = ref(false)
  const updatedPermissions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refTeachersList.value ? refTeachersList.value.localItems.length : 0
    return {
      from: per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refTeachersList.value.refresh()
  }

  watch([current_page, per_page, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchTeachers', {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then(response => {
        const { data: users, total } = response.data.data

        callback(users)
        totalUsers.value = total
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const submitTeacher = (ctx, callback) => {
    store.dispatch('app-user/addTeacher', {
      first_name: addTeacherFirstName.value,
      last_name: addTeacherLastName.value,
      email: addTeacherEmail.value,
      phone_number: addTeacherPhoneNumber.value,
      password: addTeacherPassword.value,
      password_confirmation: addTeacherConfirmPassword.value,
      school_id: addToSchoolId.value,
    })
    .then(response => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          icon: 'CheckIcon',
          title: 'Teacher added successfully',
          variant: 'success'
        }
      })
      addTeacherFirstName.value = ""
      addTeacherLastName.value = ""
      addTeacherEmail.value = ""
      addTeacherPhoneNumber.value = ""
      addTeacherPassword.value = ""
      addTeacherConfirmPassword.value = ""
      addToSchoolId.value = ""
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertTriangleIcon',
          title: 'Error registering teacher',
          variant: 'danger'
        }
      })
    })
  }

  const addTeacherToSchool = (ctx, callback) => {
    addToSchoolTeacherId.value = teacherData.value.id

    store.dispatch('app-user/addTeacherToSchool', {
      school_id: addToSchoolId.value,
      teacher_id: addToSchoolTeacherId.value
    })
    .then(response => {
      teacherData.value = response.data.data
      toast({
        component: ToastificationContent,
        props: {
          title: "Teacher Added to School",
          icon: "CheckIcon",
          variant: "success"
        }
      })
      addToSchoolModal.value = false
      addToSchoolId.value = ''
      addToSchoolTeacherId.value = ''
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error updating teacher',
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
    })
  }

  const submitUpdatedPermissions = (ctx, callback) => {
    store.dispatch('app-users/updateTeacherPermissions', {
      permissions: updatedPermissions.value
    })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'student') return 'primary'
    if (role === 'teacher') return 'warning'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'student') return 'UserIcon'
    if (role === 'teacher') return 'SettingsIcon'
    return 'UserIcon'
  }

  return {
    fetchUsers,
    tableColumns,
    per_page,
    current_page,
    totalUsers,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTeachersList,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    refetchData,

    // Extra Filters
    roleFilter,

    submitTeacher,
    addTeacherFirstName,
    addTeacherLastName,
    addTeacherEmail,
    addTeacherPhoneNumber,
    addTeacherPassword,
    addTeacherConfirmPassword,

    teacherData,

    addToSchoolModal,
    addToSchoolId,
    addToSchoolTeacherId,
    addTeacherToSchool,

    updatePermissionsModal,
    updatedPermissions,
    submitUpdatedPermissions
  }
}
