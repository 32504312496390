<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>

            <b-button
              variant="primary"
              class="ml-1"
              @click="addTeacherModal = true"
            >
              Register New Teacher
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Register New Teacher Modal -->
      <b-modal
        v-model="addTeacherModal"
        hide-footer
        centered
        title="Register New Teacher"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Enter First Name"
              label-for="enterFirstName"
            >
              <b-form-input
                id="enterFirstName"
                v-model="addTeacherFirstName"
                placeholder="Enter First Name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Enter Last Name"
              label-for="enterLastName"
            >
              <b-form-input
                id="enterLastName"
                v-model="addTeacherLastName"
                placeholder="Enter Last Name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Enter Email"
              label-for="enterEmail"
            >
              <b-form-input
                id="enterEmail"
                v-model="addTeacherEmail"
                placeholder="Enter Email Name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Enter Phone Number"
              label-for="enterPhoneNumber"
            >
              <b-form-input
                id="enterPhoneNumber"
                v-model="addTeacherPhoneNumber"
                placeholder="Enter Phone Number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-input-group
              label="Enter Password"
              label-for="enterPasword"
              class="input-group-merge"
            >
              <b-form-input
                id="enterPassword"
                v-model="addTeacherPassword"
                placeholder="Enter Password"
                :type="passwordFieldType"
                class="form-control-merge"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-input-group
              label="Repeat Password"
              label-for="repeatPassword"
              class="input-group-merge"
            >
              <b-form-input
                id="repeatPassword"
                v-model="addTeacherConfirmPassword"
                placeholder="Re-enter password"
                :type="passwordConfirmFieldType"
                class="form-control-merge"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordConfirmToggleIcon"
                  @click="togglePasswordConfirmVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Select School"
              label-for="selectSchool"
            >
              <div>
                <b-form-select
                  v-model="addToSchoolId"
                  :options="schools"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="outline-primary"
          @click="submitNewTeacher()"
        >
          Add Teacher
        </b-button>
      </b-modal>

      <b-table
        ref="refTeachersList"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.first_name)"
                :variant="`light-${resolveUserRoleVariant(
                  data.item.roles[0].name
                )}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.first_name }} {{ data.item.last_name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.roles[0].name)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.roles[0].name)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.roles[0].name
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="outline-info"
            :to="{ name: 'apps-teachers-view', params: { id: data.item.id } }"
          >
            Details
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalUsers"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BModal,
  BInputGroupAppend,
  BFormGroup,
  BFormSelect,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useTeachersList from './useTeachersList'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BModal,
    BFormGroup,
    BInputGroupAppend,
    BFormSelect,
    BInputGroup,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const addTeacherModal = ref(false)

    const roleOptions = [
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
    ]

    const passwordFieldType = ref('password')
    const passwordConfirmFieldType = ref('password')

    const passwordToggleIcon = passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    const passwordConfirmToggleIcon = passwordConfirmFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'

    const togglePasswordConfirmVisibility = () => {
      passwordConfirmFieldType.value = passwordConfirmFieldType.value === 'password' ? 'text' : 'password'
    }

    const schools = []
    const fetchSchools = () => {
      store
        .dispatch('app-user/fetchAllSchools')
        .then(response => {
          response.data.forEach(school => {
            schools.push({ value: school.id, text: school.name })
          })
        })
        .catch(error => {
          console.log(error)
        })
    }

    fetchSchools()

    const {
      fetchUsers,
      tableColumns,
      per_page,
      current_page,
      totalUsers,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTeachersList,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      // Extra Filters
      roleFilter,
      statusFilter,

      // Add Teacher
      submitTeacher,
      addTeacherFirstName,
      addTeacherLastName,
      addTeacherEmail,
      addTeacherPhoneNumber,
      addTeacherPassword,
      addTeacherConfirmPassword,
      addToSchoolId,
    } = useTeachersList()

    const submitNewTeacher = () => {
      submitTeacher()
      addTeacherModal.value = false
    }

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      per_page,
      current_page,
      totalUsers,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTeachersList,
      refetchData,

      addTeacherModal,

      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      roleOptions,

      // Extra Filters
      roleFilter,
      statusFilter,

      togglePasswordConfirmVisibility,

      // Add Teacher
      passwordFieldType,
      passwordConfirmFieldType,
      passwordToggleIcon,
      passwordConfirmToggleIcon,
      submitNewTeacher,
      submitTeacher,
      addTeacherFirstName,
      addTeacherLastName,
      addTeacherEmail,
      addTeacherPhoneNumber,
      addTeacherPassword,
      addTeacherConfirmPassword,
      addToSchoolId,

      schools,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
